<template>
    <div class="xx">
        <v-container fluid class="Information pa-0">
            <v-row class="main" no-gutters>
                <v-col cols="12" class="text-center text-section">
                    <div class="header--text">{{$t('information_title')}}</div>
                    <div class="header--subtext">
                        {{$t('information_subtitle')}}
                    </div>
                </v-col>
                <v-col cols="12" class="text-center input-section">
                    <v-row justify="center" align-content="space-between">
                        <v-col cols="12"></v-col>
                        <v-col cols="12" xs="12" class="d-flex justify-center align-center"
                            style="flex-direction:column;">
                            <div class="input-text-x mt-5">
                                <v-text-field outlined rounded background-color="white" v-model="name_th"
                                    class="input-text" hide-details dense height="44"
                                    :placeholder="$t('input_th_place')">
                                </v-text-field>
                            </div>
                            <div class="input-text-x mt-5">
                                <v-text-field outlined rounded background-color="white" v-model="name_en"
                                    class="input-text" hide-details dense height="44"
                                    :placeholder="$t('input_en_place')">
                                </v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="8" xs="8" sm="6" md="4">
                            <v-btn class="button-thai" block rounded large @click="sendInformation">{{$t('next')}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <loading v-show="loadingState"></loading>
        <alert type="error" :header="$t('failed')" :title="$t('information_faild')" v-show="showAlert"
            @done="showAlert = !showAlert"></alert>
    </div>
</template>

<script>
    import Alert from "../components/alert";
    import Loading from "../components/loading";
    export default {
        name: "information",
        components: {
            'loading': Loading,
            'alert': Alert,
        },
        data() {
            return {
                loadingState: false,
                showAlert: false,
                name_th: "",
                name_en: "",
            }
        },
        methods: {
            async sendInformation() {
                this.loadingState = true;
                try {
                    const config = {
                        headers: {
                            'x-parse-session-token': localStorage.getItem('sessionToken_casalapin')
                        }
                    }
                    let params =  {
                        name_th: this.name_th,
                        name_en: this.name_en
                    }
                    let res = await this.$axios.post('/update_user_detail',params,config);
                    this.$router.push('/');
                } catch (error) {
                    this.showAlert = true;
                }
                this.loadingState = false;
            }
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .xx {
        height: 100%;
        width: 100%;
    }

    .Information {
        background-image: url("/Image/BG.png");
        background-size: cover;
        background-position: center top;
        background-repeat: no-repeat;
        min-height: 600px;
        height: 100%;

        .main {
            position: relative;
            min-height: 500px;
            height: 100%;
            flex-flow: column;
        }

        .text-section {
            flex: 0 1 100px;
            color: white;
            padding: 0px 24px;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .header--text {
                font-size: 1.5em;
            }

            .header--subtext {
                font-size: 0.8em;
                word-wrap: break-word;
            }
        }

        .input-section {
            padding: 0px 24px 10px 24px;
            display: flex;
            flex: 1 1 auto;
            background-image: url("/Image/BG_3.png");
            background-size: contain;
            background-repeat: repeat;

            .button-thai {
                font-weight: 800;
                color: white;
                background-color: $base-color !important;
            }
        }

        .input-text-x {
            max-width: 400px;
            width: 100%;
        }
    }
</style>